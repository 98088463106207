import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


$(document).ready(function() {



	$(document).on("invalid.zf.abide", function(ev,elem) {
        console.log("Field INVALID ### id "+ev.target.id+" is invalid");
        $('.form-success-message').hide();
    }).on("valid.zf.abide", function(ev,elem) {
        console.log("Field name "+elem.attr('name')+" is valid");
    }).on("forminvalid.zf.abide", function(ev,frm) {
        console.log("INVALID #### Form id "+ev.target.id+" is invalid");
    }).on("formvalid.zf.abide", function(ev,frm) {
        console.log("Form id "+frm.attr('id')+" is valid");
    }).on("submit", function(ev) {
        //ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
        //$('form#contact-form').submit();
    });

	$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		console.log('Foundation.MediaQuery newSize ' + newSize)
		console.log('Foundation.MediaQuery oldSize ' + oldSize)
	});



	var headerHeight = $('header').outerHeight();

	if($(".static").length){
		$('.dropdown.menu a').removeClass('page-scroll');
	}

	$(function() {
	    $('#dot-nav').css({
	        'position' : 'fixed',
	        'right' : '8px',
	        'top' : '50%',
	        'margin-top' : -$('#dot-nav').outerHeight()/2
	    });
	});

	$(window).scroll(function() {

	    var windscroll = $(window).scrollTop(),
	    	navdata = $(this).data("id");

	    if (windscroll >= 100) {

	        $('body section').each(function(i) {

	            if ($(this).position().top <= windscroll + headerHeight) {

	            	var thid = $(this).attr('id');

	            	$('.dropdown.menu a.active').removeClass('active');

                	$('.dropdown.menu a').eq(i).addClass('active');

                	$('.dotnav a.active').removeClass('active');

                	$('.dotnav a').eq(i).addClass('active');

	        
	            }
	        });

	    } else {

	       	$('.dropdown.menu a.active').removeClass('active');

        	$('.dropdown.menu a:first').addClass('active');
	    }

	}).scroll();

	

	$('.page-scroll').click(function(e) {

		e.preventDefault();

		var targetHref   = $(this).data("target");

		console.log('header = ' + headerHeight)

		$('html, body').animate({

			scrollTop: $(targetHref).offset().top - headerHeight 

		}, 500);

		if(Foundation.MediaQuery.is('medium down')){
			$('#main-menu').hide(250);
		}

	});


	$('.close-main-menu').on('click', function(event){
		$('#main-menu').hide(250);
	})


    


});




	
  	
  	
	  
	